import React from "react";

// Components
import { Header } from "./header";

export const App = ({
  location,
  data,
  children,
  isSplashOpen,
  setIsSplashOpen,
}) => {
  return (
    <>
      <Header
        location={location}
        isSplashOpen={isSplashOpen}
        setIsSplashOpen={setIsSplashOpen}
      />
      <main>{children}</main>
    </>
  );
};
