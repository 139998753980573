import React, { useRef, useState } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { useKeyPressEvent } from "react-use";
import Modal from "react-modal";

// Slideshow
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  width: 100%;
  height: 100%;
  height: ${(props) => props.height}px;
  padding: 25px;

  z-index: 10;
  background-color: #121929;

  & .slideshow-image-container {
    padding: 15px;
  }

  & .react-slideshow-container,
  & .react-slideshow-wrapper,
  & .images-wrap,
  & .images-wrap > div,
  & .image-slide,
  & .video-slide {
    position: relative;

    width: 100%;
    height: 100%;
    max-height: calc(${(props) => props.height}px - 25px - 25px);
  }

  & .image-slide > div {
    height: calc(${(props) => props.height}px - 25px - 25px);
  }

  & .gatsby-image-wrapper {
    height: 100%;
  }

  & img {
    width: 100%;
    height: 100%;

    max-height: calc(${(props) => props.height}px - 25px - 25px);

    padding: 90px 0;

    object-fit: contain !important;

    @media (max-width: 860px) {
      padding: 0;
      object-fit: cover !important;
    }
  }

  & .react-slideshow-slide-images-wrap {
    align-items: center;
  }

  & .caption {
    padding: 30px;
    text-align: center;

    & p {
      margin: 0;
    }

    & a {
      color: rgba(153, 153, 153, 1);
    }

    &.top {
      display: grid;
      grid-template-columns: 20px 1fr 20px;
      grid-column-gap: 30px;
      align-items: baseline;

      & .close-button {
        & .desktop-close-icon {
          display: block;

          @media (max-width: 860px) {
            display: none;
          }
        }

        & .mobile-close-icon {
          display: none;

          @media (max-width: 860px) {
            display: block;
          }
        }
      }

      @media (max-width: 860px) {
        grid-template-columns: 14px 1fr 14px;
      }
    }

    @media (max-width: 860px) {
      padding: 20px;
    }
  }

  &.residency-gallery {
    background-color: #000;
    color: #eeeeee;

    & svg {
      & path {
        stroke: #eeeeee;
      }
    }
  }

  @media (max-width: 860px) {
    padding: 0 0 0 0;

    max-height: 100%;

    & .react-slideshow-container,
    & .react-slideshow-wrapper,
    & .images-wrap,
    & .images-wrap > div,
    & .image-slide,
    & .video-slide,
    & .image-slide > div,
    & img {
      height: 100%;
      max-height: 100%;

      object-fit: cover !important;
    }
  }
`;

const Arrow = styled.button`
  position: absolute;
  top: 120px;
  bottom: 120px;
  width: 50%;

  z-index: 10;

  &.previous {
    left: 0;

    cursor: url(/icons/previous@1x.png) 0 0, w-resize !important;
    cursor: -webkit-image-set(
          url(/icons/previous@1x.png) 1x,
          url(/icons/previous@2x.png) 2x
        )
        0 0,
      w-resize !important;
  }

  &.next {
    right: 0;

    cursor: url(/icons/next@1x.png) 0 0, e-resize !important;
    cursor: -webkit-image-set(
          url(/icons/next@1x.png) 1x,
          url(/icons/next@2x.png) 2x
        )
        0 0,
      e-resize !important;
  }

  & svg {
    & path {
      transition: 250ms stroke ease;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & svg {
        & path {
          stroke: #dbaf96;
        }
      }
    }
  }

  @media (hover: none) and (pointer: coarse) {
    display: none;
  }
`;

const CloseButton = styled.button`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;

  padding: 22px 17px;
  color: #fff;

  cursor: pointer;
`;

Modal.setAppElement("#___gatsby");

export const LightboxGallery = ({
  initialSlideshowIndex,
  media,
  setIsSlideshowOpen,
  isSlideshowOpen,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  // Window Height
  const height = use100vh();

  // Slideshow Reference
  const slideRef = useRef();

  // Keyboard navigation events for slideshow
  const goBack = () => {
    if (slideRef.current !== undefined && media.length > 1) {
      slideRef.current.goBack();
    }
  };

  const goNext = () => {
    if (slideRef.current !== undefined && media.length > 1) {
      slideRef.current.goNext();
    }
  };
  useKeyPressEvent("ArrowRight", goNext);
  useKeyPressEvent("ArrowLeft", goBack);

  return (
    <Modal
      isOpen={isSlideshowOpen}
      portalClassName={`ReactModalPortal-artworks-lightbox`}
      className="lightbox-modal"
      contentLabel={`Artworks Lightbox`}
      shouldFocusAfterRender={false}
    >
      <GalleryContainer className={`lightbox-gallery`} height={height}>
        {media.length > 1 ? (
          <>
            {media.length > 1 && (
              <Arrow
                aria-label="Previous Slide"
                className={`previous arrow`}
                onClick={() => {
                  slideRef.current.goBack();
                }}
              />
            )}

            <Slide
              ref={slideRef}
              arrows={false}
              transitionDuration={750}
              pauseOnHover={false}
              autoplay={false}
              infinite={true}
              canSwipe={media.length > 1 ? true : false}
              defaultIndex={initialSlideshowIndex}
              onChange={(previous, next) => updateCurrentSlide(next)}
            >
              {media}
            </Slide>

            {media.length > 1 && (
              <Arrow
                aria-label="Next Slide"
                className={`next arrow`}
                onClick={() => {
                  slideRef.current.goNext();
                }}
              />
            )}
          </>
        ) : (
          <div>{media}</div>
        )}

        <CloseButton onClick={() => setIsSlideshowOpen(false)}>
          Close
        </CloseButton>
      </GalleryContainer>
    </Modal>
  );
};
