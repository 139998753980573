import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Scrollspy from "react-scrollspy";

// Icons
import { Logo } from "../icons/logo";
import { Open } from "../icons/menu-icons";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 0 15px;

  background-color: #f8f6f4;
  z-index: 200;

  & .top-bar {
    position: relative;
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    align-items: center;

    height: 35px;
    line-height: 35px;

    z-index: 20;

    & svg.logo {
      display: block;

      /* width: 270px; */
      width: calc(${(props) => props.right}px - 19.5px);
      height: auto;

      margin: 0 auto;
    }

    & button {
      cursor: pointer;
      text-align: right;
      line-height: 9px;

      & svg {
        transition: 300ms transform ease;
      }
    }
  }

  & .menu-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #121929;
    color: #fff;

    padding: 60px 15px 20px 15px;

    z-index: 10;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    align-items: flex-end;

    transition: 300ms transform ease;
    transform: translateY(-100%);

    & ol {
      & li {
        line-height: 30px;
      }
    }

    & nav {
      & .nav-container {
        & button {
          display: block;
          cursor: pointer;

          line-height: 30px;

          &.is-current,
          &:hover {
            font-weight: 700;
          }
        }
      }
    }
  }

  &.visible {
    & .top-bar {
      & svg.logo {
        & path {
          fill: #fff;
        }
      }

      & button.toggle-menu {
        & svg {
          transform: rotate(45deg);

          & path {
            fill: #fff;
          }
        }
      }
    }

    & .menu-container {
      transform: translateY(0);
    }
  }

  @media (max-width: 680px) {
    & .menu-container {
      & ol {
        & li {
          font-size: 15px;
          line-height: 40px;
        }
      }

      & nav {
        & .nav-container {
          & button {
            font-size: 15px;
            line-height: 40px;
          }
        }
      }
    }
  }
`;

export const MobileMenu = ({
  scrollToSection,
  isMenuOpen,
  setIsMenuOpen,
  setIsSplashOpen,
}) => {
  const [right, setRight] = useState(0);
  useEffect(() => {
    const logoPosition = document
      .getElementById("group-2")
      .getBoundingClientRect().right;

    setRight(logoPosition);
  }, []);

  return (
    <HeaderWrapper
      className={isMenuOpen ? `visible` : `hidden`}
      right={Math.round(right)}
    >
      <div className="top-bar">
        <div className="spacer" />

        <Logo setIsSplashOpen={setIsSplashOpen} />

        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          type="button"
          className="toggle-menu"
        >
          <Open />
        </button>
      </div>

      <div className="menu-container">
        <div className="column left">
          <nav>
            <div className="nav-container">
              <Scrollspy
                componentTag={`div`}
                items={[
                  "about",
                  "artists",
                  "institutions",
                  "events",
                  "collaborators",
                  "supporters",
                  "resources",
                  "donate",
                ]}
                currentClassName="is-current"
                offset={-150}
              >
                <button
                  onClick={() => {
                    scrollToSection(`about`);
                  }}
                >
                  About
                </button>
                <button
                  onClick={() => {
                    scrollToSection(`artists`);
                  }}
                >
                  Artists
                </button>
                <button
                  onClick={() => {
                    scrollToSection(`institutions`);
                  }}
                >
                  Institutions
                </button>
                <button
                  onClick={() => {
                    scrollToSection(`events`);
                  }}
                >
                  Events
                </button>
                <button
                  onClick={() => {
                    scrollToSection(`collaborators`);
                  }}
                >
                  Collaborators
                </button>
                <button
                  onClick={() => {
                    scrollToSection(`supporters`);
                  }}
                >
                  Supporters
                </button>
                <button
                  onClick={() => {
                    scrollToSection(`resources`);
                  }}
                >
                  Resources
                </button>
                <button
                  onClick={() => {
                    scrollToSection(`donate`);
                  }}
                >
                  Donate
                </button>
              </Scrollspy>
            </div>
          </nav>
        </div>

        <div className="column right">
          <ol>
            <li>
              <a href="mailto:info@artistsforwomanlifefreedom.com">Contact</a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/artistsforwomanlifefreedom/"
                target="_blank"
              >
                Instagram
              </a>
            </li>
          </ol>
        </div>
      </div>
    </HeaderWrapper>
  );
};
