import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
body {
  font-family: "aktiv-grotesk", sans-serif;
  font-weight: 400;
  font-style: normal;

  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.02em;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;


  @media (max-width: 680px) {
    font-size: 13px;
    line-height: 20px;
  }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.02em;

  font-family: "aktiv-grotesk", sans-serif;
  font-weight: 700;
  font-style: normal;

  margin: 0;

  @media (max-width: 680px) {
    font-size: 13px;
    line-height: 20px;
  }
}

.uppercase {
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

strong {
  font-weight: 700;
  font-style: normal;
}

/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    color: inherit;
    background-color: transparent;
    border:0;
    margin:0;
    padding:0;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ol, ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}


/*--------------------------------------------------------------
  ## React Modal
--------------------------------------------------------------*/
.ReactModalPortal{
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 100;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModalPortal-lightbox,
.ReactModalPortal-splash,
.ReactModalPortal-artworks-lightbox {
  position: fixed !important;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 10000;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: #fff !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModalPortal-splash .ReactModal__Overlay,
.ReactModalPortal-lightbox .ReactModal__Overlay {
  background-color: transparent !important;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content{
  width: 100%;
  height: 100%;

  inset: unset !important;
  background: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}
`;

export default GlobalStyle;
