import * as React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Pages
import Index from "./src/pages/index";

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          home: Index,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
