import React, { useState, useEffect, useRef, useContext } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { use100vh } from "react-div-100vh";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import { IsSplashOpen } from "../components/context/splash-context";

// Components
import { LightboxGallery } from "../components/images/lightbox-gallery";
import { Splash } from "../components/global/splash";

const Page = styled.div`
  position: relative;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & p {
    & a {
      transition: 300ms color ease;

      &:hover {
        color: #fd4f50;
      }
    }
  }

  & .grid {
    display: grid;
  }

  & .grid-8 {
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 15px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  & .image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: sticky;
    top: 0;

    z-index: 90;

    grid-column: 2 / 4;
    height: ${(props) => props.height}px;

    & > div {
    }

    & .gatsby-image-wrapper {
      display: none;

      &.is-current {
        display: block;
      }
    }
  }

  & section {
    position: relative;
    grid-column: 5 / 9;

    padding: 90px 30px;

    & h2 {
      margin: 0 0 2em 0;
    }

    &.light-bg {
      background-color: #f8f6f4;
    }

    &.dark-bg {
      background-color: #f5f2ee;
    }

    & .text-container {
      max-width: 565px;

      & p {
        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &#about {
      padding: 300px 30px 90px 30px;
    }

    &#artists {
      padding: 0;

      & .single-artist-container {
        padding: 50px 30px;

        &:first-of-type {
          padding: 90px 30px 50px 30px;
        }

        &:last-of-type {
          padding: 50px 30px 90px 30px;
        }

        &:nth-of-type(odd) {
          background-color: #f5f2ee;
        }

        &:nth-of-type(even) {
          background-color: #f8f6f4;
        }

        & .artist-image-container {
          grid-column: 1 / 5;

          width: 100%;
          max-width: 415px;

          margin: 0 auto;

          & .gatsby-image-wrapper {
            position: sticky !important;
            top: 50px;
          }

          & img {
            cursor: pointer;
          }
        }

        & .artist-text-container {
          grid-column: 5 / 9;
          max-width: 565px;

          & p {
            &:first-of-type {
              margin-top: 0;
            }

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &#institutions {
      & .logos-container {
        margin: 45px 0 0 0;

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        grid-column-gap: 35px;
        grid-row-gap: 40px;

        & img {
          object-fit: contain !important;
        }
      }
    }

    &#events {
      & .single-event-container {
        margin: 0 0 60px 0;

        &:last-of-type {
          margin: 0;
        }

        & .event-image-container {
          grid-column: 1 / 5;

          width: 100%;
          max-width: 415px;

          margin: 0 auto;

          & img {
            cursor: pointer;
          }
        }

        & .event-text-container {
          grid-column: 5 / 9;
          max-width: 565px;

          & p {
            &:first-of-type {
              margin-top: 0;
            }

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &#resources {
      & .inner-text-container {
        margin: 0 0 2em 0;
      }

      & .single-resource-container {
        margin: 0 0 2em 0;

        &:last-of-type {
          margin: 0;
        }

        & a {
          transition: 300ms color ease;

          &:hover {
            color: #fd4f50;
          }
        }

        & p {
          margin: 0;

          & a {
            &::before {
              content: url("/icons/arrow-black.svg");
              display: inline-block;
              width: 15px;
              height: 15px;
              margin-right: 5px;
            }

            &:hover {
              &::before {
                content: url("/icons/arrow-red.svg");
              }
            }
          }
        }
      }
    }

    &#supporters {
      & p {
        margin: 0;
      }
    }

    &#donate {
      & .donation-button {
        margin: 45px 0 0 0;

        & a {
          display: block;
          border: 1px solid #000000;

          width: 155px;
          height: 52px;

          text-align: center;
          line-height: 52px;

          transition: 300ms all ease;

          &:hover {
            background-color: #121929;
            color: #fff;
          }
        }
      }
    }

    & .section-title,
    & .text-container {
      grid-column: 5 / 9;
      max-width: 565px;
    }

    @media (max-width: 768px) {
      grid-column: 1 / 7;

      &#artists {
        & .single-artist-container {
          & .artist-image-container,
          & .artist-text-container {
            grid-column: 1 / 7;
          }

          & .artist-image-container {
            margin: 0 auto 50px auto;
          }
        }
      }

      &#events {
        & .single-event-container {
          & .event-image-container,
          & .event-text-container {
            grid-column: 1 / 7;
          }

          & .event-image-container {
            margin: 0 auto 50px auto;
          }
        }
      }

      &#institutions {
        & .logos-container {
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 30px;
          grid-row-gap: 30px;
        }
      }

      &#donate {
        & .donation-button {
          & a {
            height: 42px;
            line-height: 42px;

            margin: 0 auto;
          }
        }
      }

      & .section-title,
      & .text-container {
        grid-column: 1 / 7;
      }
    }

    @media (max-width: 680px) {
      padding: 55px 15px;

      &#about {
        padding: 175px 15px 50px 15px;
      }
      &#artists {
        & .single-artist-container {
          padding: 50px 15px;

          &:first-of-type {
            padding: 50px 15px 50px 15px;
          }

          &:last-of-type {
            padding: 50px 15px 50px 15px;
          }
        }
      }
    }
  }
`;

const Navigation = styled.nav`
  /* position: sticky; */
  position: fixed;
  bottom: 60px;
  left: 30px;

  /* padding: 0 30px; */

  & ol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & li {
      margin: 0 30px 0 0;
      cursor: pointer;

      &#about-button {
        width: 45px;
      }
      &#artists-button {
        width: 49px;
      }
      &#institutions-button {
        width: 77px;
      }
      &#donate-button {
        width: 52px;
      }
      &#donors-button {
        width: 164px;
      }

      &.is-current {
        font-weight: bold;
      }

      &:hover {
        font-weight: bold;
      }
    }
  }
`;

const Index = ({ data, isPrismicPreview }) => {
  // window height
  const height = use100vh();

  // Splash
  const [isSplashOpen, setIsSplashOpen] = useContext(IsSplashOpen);

  // Slideshow
  const [isSlideshowOpen, setIsSlideshowOpen] = useState(false);
  const [initialSlideshowIndex, setInitialSlideshowIndex] = useState(0);

  // Sections
  const aboutRef = useRef(null);
  const artistRefs = Array(data.prismicHome.data.artist.length)
    .fill()
    .map(() => React.createRef());
  const institutionsRef = useRef(null);
  const eventsRef = useRef(null);
  const collaboratorsRef = useRef(null);
  const supportersRef = useRef(null);
  const resourcesRef = useRef(null);
  const donateRef = useRef(null);

  const visibleSection = useVisibleSection([
    aboutRef,
    ...artistRefs,
    institutionsRef,
    eventsRef,
    collaboratorsRef,
    supportersRef,
    resourcesRef,
    donateRef,
  ]);

  const media = data.prismicHome.data.artist.map((artist, index) => (
    <div className="slideshow-image-container">
      <GatsbyImage image={artist.artwork.gatsbyImageData} />
    </div>
  ));

  const artists = data.prismicHome.data.artist.map((artist, index) => (
    <div
      className="single-artist-container grid grid-8"
      key={`single_artist_${index}`}
      id={`artist-${index}`}
      ref={artistRefs[index]}
    >
      {index === 0 && (
        <div className="section-title">
          <h2 className="uppercase">Artists</h2>
        </div>
      )}

      <div
        className="artist-image-container"
        onClick={() => {
          setIsSlideshowOpen(true);
          setInitialSlideshowIndex(index);
        }}
      >
        <GatsbyImage image={artist.artwork.gatsbyImageData} />
      </div>

      <div className="artist-text-container">
        <PrismicRichText field={artist.artist_text.richText} />
      </div>
    </div>
  ));

  const events = data.prismicHome.data.events.map((event, index) => (
    <div
      className="single-event-container grid grid-8"
      key={`single_event_${index}`}
      id={`event-${index}`}
    >
      <div className="event-image-container">
        <GatsbyImage image={event.event_image.gatsbyImageData} />
      </div>

      <div className="event-text-container">
        <PrismicRichText field={event.event_title.richText} />
        <PrismicRichText field={event.event_date.richText} />
        <PrismicRichText field={event.event_text.richText} />
      </div>
    </div>
  ));

  const logos = data.prismicHome.data.logos.map((logo, index) => (
    <div className="single-logo-container" key={`single_logo_${index}`}>
      <GatsbyImage image={logo.logo.gatsbyImageData} />
    </div>
  ));

  const resources = data.prismicHome.data.resources.map((resource, index) => (
    <div className="single-resource-container" key={`resource${index}`}>
      <div className="resource-text-container">
        <PrismicRichText field={resource.resource_title.richText} />
        <PrismicRichText field={resource.resource_text.richText} />
      </div>
    </div>
  ));

  function useVisibleSection(refs) {
    const [visibleSection, setVisibleSection] = useState(null);

    useEffect(() => {
      const handleScroll = () => {
        const sections = refs.map((ref) => {
          const element = ref.current;
          const rect = element.getBoundingClientRect();
          return {
            element,
            top: rect.top,
            bottom: rect.bottom,
          };
        });

        const visibleSection = sections.find(
          (section) => section.top <= 0 && section.bottom >= 0
        );

        if (visibleSection) {
          setVisibleSection(visibleSection.element);
        } else {
          setVisibleSection(null);
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [refs]);

    return visibleSection;
  }

  useEffect(() => {
    if (visibleSection) {
      const bgColor = window.getComputedStyle(visibleSection).backgroundColor;
      document.querySelector("header").style.backgroundColor = bgColor;
    }
  }, [visibleSection]);

  return (
    <>
      <Page height={height} id="page">
        <section id="about" className="light-bg" ref={aboutRef}>
          <div className="grid grid-8">
            <div className="text-container">
              <PrismicRichText
                field={data.prismicHome.data.about_text.richText}
              />
            </div>
          </div>
        </section>

        <section id="artists">
          <div className="artists-container">{artists}</div>
        </section>

        <section id="institutions" className="dark-bg" ref={institutionsRef}>
          <div className="grid grid-8">
            <div className="text-container">
              <h2 className="uppercase">Institutions</h2>

              <PrismicRichText
                field={data.prismicHome.data.institutions_text.richText}
              />

              <div className="logos-container">{logos}</div>
            </div>
          </div>
        </section>

        <section id="events" className="light-bg" ref={eventsRef}>
          <div className="grid grid-8">
            <div className="text-container">
              <h2 className="uppercase">Events</h2>
            </div>
          </div>

          <div className="events-container">{events}</div>
        </section>

        <section id="collaborators" className="dark-bg" ref={collaboratorsRef}>
          <div className="grid grid-8">
            <div className="text-container">
              <h2 className="uppercase">Collaborators</h2>

              <PrismicRichText
                field={data.prismicHome.data.collaborators_text.richText}
              />
            </div>
          </div>
        </section>

        <section id="supporters" className="light-bg" ref={supportersRef}>
          <div className="grid grid-8">
            <div className="text-container">
              <h2 className="uppercase">Supporters</h2>

              <PrismicRichText
                field={data.prismicHome.data.supporters_text.richText}
              />
            </div>
          </div>
        </section>

        <section id="resources" className="dark-bg" ref={resourcesRef}>
          <div className="grid grid-8">
            <div className="text-container">
              <div className="inner-text-container">
                <h2 className="uppercase">Resources</h2>
                <PrismicRichText
                  field={data.prismicHome.data.resources_text.richText}
                />
              </div>

              <div className="resources-container">{resources}</div>
            </div>
          </div>
        </section>

        <section id="donate" ref={donateRef} className="light-bg">
          <div className="grid grid-8">
            <div className="text-container">
              <h2 className="uppercase">Donate</h2>

              <PrismicRichText
                field={data.prismicHome.data.donate_text.richText}
              />

              <div className="donation-button">
                <a
                  href={data.prismicHome.data.donation_link.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Donate
                </a>
              </div>
            </div>
          </div>
        </section>
      </Page>

      {isSlideshowOpen && (
        <LightboxGallery
          initialSlideshowIndex={initialSlideshowIndex}
          isSlideshowOpen={isSlideshowOpen}
          setIsSlideshowOpen={setIsSlideshowOpen}
          media={media}
        />
      )}

      {isSplashOpen && !isPrismicPreview && (
        <Splash isSplashOpen={isSplashOpen} setIsSplashOpen={setIsSplashOpen} />
      )}
    </>
  );
};

export default withPrismicPreview(Index);

export const query = graphql`
  {
    prismicHome {
      _previewable
      data {
        about_text {
          richText
        }
        artist {
          artist_text {
            richText
          }
          artwork {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        institutions_text {
          richText
        }
        logos {
          logo {
            gatsbyImageData(aspectRatio: 2.5)
          }
        }
        collaborators_text {
          richText
        }
        donate_text {
          richText
        }
        donation_link {
          url
        }
        events {
          event_title {
            richText
          }
          event_text {
            richText
          }
          event_date {
            richText
          }
          event_image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        resources {
          resource_title {
            richText
          }
          resource_text {
            richText
          }
        }
        resources_text {
          richText
        }
        supporters_text {
          richText
        }
      }
    }
  }
`;
