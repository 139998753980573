import React, { useState } from "react";
import styled from "styled-components";
import Scrollspy from "react-scrollspy";

// Icons
import { Logo } from "../icons/logo";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: grid;
  grid-template-columns: 1fr 331px 1fr;
  align-items: baseline;

  padding: 0 30px;

  background-color: #f8f6f4;
  z-index: 200;

  height: 52px;
  line-height: 55px;

  & .logo-button {
    display: block;
    cursor: pointer;
    z-index: 1000;
  }

  & ol {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & li {
      margin: 0 30px 0 0;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  & nav {
    & button {
      cursor: pointer;
    }
    & .nav-container {
      position: absolute;
      top: 15px;

      & button {
        display: block;
        cursor: pointer;

        line-height: 26px;
        margin: 0 0 13px 0;

        &.is-current,
        &:hover {
          font-weight: 700;
        }
      }
    }
  }
`;

export const DesktopMenu = ({
  scrollToSection,
  isMenuOpen,
  setIsMenuOpen,
  isSplashOpen,
  setIsSplashOpen,
}) => {
  return (
    <HeaderWrapper>
      <nav>
        {!isMenuOpen && (
          <button type="button" onClick={() => setIsMenuOpen(true)}>
            Menu
          </button>
        )}

        {isMenuOpen && (
          <div className="nav-container">
            <Scrollspy
              componentTag={`div`}
              items={[
                "about",
                "artists",
                "institutions",
                "events",
                "collaborators",
                "supporters",
                "resources",
                "donate",
              ]}
              currentClassName="is-current"
              offset={-150}
            >
              <button onClick={() => scrollToSection(`about`)}>About</button>
              <button onClick={() => scrollToSection(`artists`)}>
                Artists
              </button>
              <button onClick={() => scrollToSection(`institutions`)}>
                Institutions
              </button>
              <button onClick={() => scrollToSection(`events`)}>Events</button>
              <button onClick={() => scrollToSection(`collaborators`)}>
                Collaborators
              </button>
              <button onClick={() => scrollToSection(`supporters`)}>
                Supporters
              </button>
              <button onClick={() => scrollToSection(`resources`)}>
                Resources
              </button>
              <button onClick={() => scrollToSection(`donate`)}>Donate</button>
            </Scrollspy>
          </div>
        )}
      </nav>

      <Logo setIsSplashOpen={setIsSplashOpen} />

      <ol>
        <li>
          <a href="mailto:info@artistsforwomanlifefreedom.com">Contact</a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/artistsforwomanlifefreedom/"
            target="_blank"
          >
            Instagram
          </a>
        </li>
      </ol>
    </HeaderWrapper>
  );
};
