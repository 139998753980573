import React, { useState } from "react";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

export const Header = ({ isSplashOpen, setIsSplashOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  const scrollToSection = (section) => {
    setIsMenuOpen(false);

    document.getElementById(section).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  if (isDesktop) {
    return (
      <DesktopMenu
        scrollToSection={scrollToSection}
        isSplashOpen={isSplashOpen}
        setIsSplashOpen={setIsSplashOpen}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      />
    );
  } else {
    return (
      <MobileMenu
        scrollToSection={scrollToSection}
        isSplashOpen={isSplashOpen}
        setIsSplashOpen={setIsSplashOpen}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      />
    );
  }
};
