import React from "react";

export const Open = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.16078 3.89421L5.16078 0L4.16078 8.42938e-08L4.16078 3.89421H0.143433V4.89421H4.16078L4.16078 9.19844H5.16078L5.16078 4.89421H9.34187V3.89421H5.16078Z"
      fill="black"
    />
  </svg>
);
