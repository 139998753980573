import React, { useState, useEffect } from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Context
import { IsSplashOpen } from "../context/splash-context";

// Components
import { App } from "./app";
import { DefaultSEO } from "./default-seo";

const Container = styled.div`
  position: relative;
`;

const Layout = ({ children }) => {
  const [isSplashOpen, setIsSplashOpen] = useState(true);

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  return (
    <IsSplashOpen.Provider value={[isSplashOpen, setIsSplashOpen]}>
      <Location>
        {({ location }) => {
          return (
            <Container location={location.pathname}>
              <Normalize />
              <GlobalStyles />
              <DefaultSEO />
              <App
                location={location.pathname}
                children={children}
                isSplashOpen={isSplashOpen}
                setIsSplashOpen={setIsSplashOpen}
              />
            </Container>
          );
        }}
      </Location>
    </IsSplashOpen.Provider>
  );
};

export default Layout;
