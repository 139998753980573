import React from "react";

export const Logo = ({ setIsSplashOpen }) => (
  <button
    className="logo-button"
    onClick={() => setIsSplashOpen(true)}
    type="button"
  >
    <svg
      width="331"
      height="12"
      viewBox="0 0 331 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="logo"
    >
      <path
        d="M3.92715 0.765198H6.06505L9.98949 11.2375H7.65969L6.82453 8.81833H3.07577L2.24061 11.2375H0L3.92715 0.765198ZM6.25695 7.01341L4.93799 3.09779L3.63525 7.01341H6.25695Z"
        fill="black"
      />
      <path
        d="M12.4949 0.765137H16.3626C17.3599 0.765137 18.1492 0.90585 18.7357 1.18186C19.3222 1.46059 19.7411 1.83672 19.9952 2.31028C20.2492 2.78383 20.3763 3.31963 20.3763 3.91495C20.3763 4.58063 20.2411 5.15702 19.9735 5.64681C19.706 6.1366 19.287 6.52356 18.7222 6.80499L20.8898 11.2348H18.5897L16.7437 7.28937C16.568 7.30019 16.3978 7.3029 16.2302 7.3029H14.6328V11.2348H12.4949V0.765137ZM16.3329 5.56022C17.0653 5.56022 17.5627 5.42492 17.8275 5.15702C18.0924 4.88912 18.2248 4.50487 18.2248 4.00696C18.2248 3.50905 18.0843 3.14644 17.8059 2.89207C17.5275 2.63771 17.0032 2.51052 16.2302 2.51052H14.6328V5.56022H16.3329Z"
        fill="black"
      />
      <path
        d="M25.9441 2.65936H22.7791V0.765137H31.2767V2.65665H28.1117V11.2375H25.9441V2.65936Z"
        fill="black"
      />
      <path
        d="M33.9253 0.765137H36.2254V11.2375H33.9253V0.765137Z"
        fill="black"
      />
      <path
        d="M46.5095 10.6151C45.8148 11.2077 44.7824 11.5027 43.404 11.5027C42.585 11.5027 41.8498 11.3809 41.1985 11.1347C40.5471 10.8884 40.0309 10.4961 39.6471 9.95486C39.2606 9.41095 39.039 8.70197 38.9795 7.8198H41.1769C41.2552 8.4936 41.4796 8.97257 41.8498 9.2567C42.2201 9.54084 42.7472 9.68155 43.431 9.68155C44.1148 9.68155 44.5986 9.55707 44.8797 9.30812C45.1635 9.05916 45.304 8.74797 45.304 8.37724C45.304 8.07417 45.2121 7.79545 45.0256 7.54108C44.8391 7.28672 44.4743 7.10541 43.9283 6.99717L42.5661 6.73198C41.5012 6.52632 40.6687 6.19077 40.0687 5.72804C39.4687 5.26531 39.1687 4.58069 39.1687 3.68229C39.1687 2.9679 39.3444 2.37798 39.6957 1.90713C40.0471 1.43629 40.5336 1.08721 41.1525 0.851784C41.7715 0.616359 42.4877 0.5 43.2985 0.5C44.2256 0.5 44.977 0.640713 45.5473 0.924846C46.1175 1.20898 46.5446 1.58782 46.8284 2.06138C47.1122 2.53493 47.2879 3.06531 47.3554 3.65252L45.2905 3.91501C45.1824 3.37921 44.9878 2.9706 44.704 2.69729C44.4202 2.42399 43.931 2.28598 43.2391 2.28598C42.9364 2.28598 42.6418 2.31845 42.3526 2.38069C42.0634 2.44563 41.8309 2.56199 41.6498 2.73247C41.4688 2.90295 41.3796 3.1492 41.3796 3.47392C41.3796 3.79865 41.4904 4.08007 41.7093 4.29656C41.9282 4.51033 42.3228 4.67269 42.8877 4.78093L44.3229 5.05966C45.3689 5.26531 46.1662 5.6198 46.7176 6.12312C47.2689 6.62645 47.5446 7.32731 47.5446 8.22841C47.5446 9.22694 47.1987 10.0198 46.504 10.6124L46.5095 10.6151Z"
        fill="black"
      />
      <path
        d="M52.807 2.65936H49.642V0.765137H58.1396V2.65665H54.9746V11.2375H52.807V2.65936Z"
        fill="black"
      />
      <path
        d="M67.7048 10.6151C67.0101 11.2077 65.9777 11.5027 64.5993 11.5027C63.7803 11.5027 63.0452 11.3809 62.3938 11.1347C61.7424 10.8884 61.2262 10.4961 60.8424 9.95486C60.4559 9.41095 60.2343 8.70197 60.1748 7.8198H62.3722C62.4506 8.4936 62.6749 8.97257 63.0452 9.2567C63.4154 9.54084 63.9425 9.68155 64.6263 9.68155C65.3101 9.68155 65.7939 9.55707 66.075 9.30812C66.3588 9.05916 66.4993 8.74797 66.4993 8.37724C66.4993 8.07417 66.4074 7.79545 66.2209 7.54108C66.0344 7.28672 65.6696 7.10541 65.1236 6.99717L63.7614 6.73198C62.6965 6.52632 61.864 6.19077 61.264 5.72804C60.664 5.26531 60.364 4.58069 60.364 3.68229C60.364 2.9679 60.5397 2.37798 60.891 1.90713C61.2424 1.43629 61.7289 1.08721 62.3478 0.851784C62.9668 0.616359 63.683 0.5 64.4939 0.5C65.4209 0.5 66.1723 0.640713 66.7426 0.924846C67.3129 1.20898 67.7399 1.58782 68.0237 2.06138C68.3075 2.53493 68.4832 3.06531 68.5507 3.65252L66.4858 3.91501C66.3777 3.37921 66.1831 2.9706 65.8993 2.69729C65.6155 2.42399 65.1263 2.28598 64.4344 2.28598C64.1317 2.28598 63.8371 2.31845 63.5479 2.38069C63.2587 2.44563 63.0262 2.56199 62.8452 2.73247C62.6641 2.90295 62.5749 3.1492 62.5749 3.47392C62.5749 3.79865 62.6857 4.08007 62.9046 4.29656C63.1235 4.51033 63.5182 4.67269 64.083 4.78093L65.5182 5.05966C66.5642 5.26531 67.3615 5.6198 67.9129 6.12312C68.4642 6.62645 68.7399 7.32731 68.7399 8.22841C68.7399 9.22694 68.394 10.0198 67.6994 10.6124L67.7048 10.6151Z"
        fill="black"
      />
      <path
        d="M83.4242 0.765137H90.3541V2.65665H85.6972V5.25173H90.0784V7.14324H85.6972V11.2348H83.4269V0.765137H83.4242Z"
        fill="black"
      />
      <path
        d="M93.5542 3.03306C93.9705 2.20773 94.557 1.57993 95.3191 1.14696C96.0813 0.716706 96.9732 0.50293 98.0003 0.50293C99.0274 0.50293 99.9139 0.714 100.668 1.14155C101.419 1.5664 101.998 2.18608 102.403 2.99518C102.809 3.80699 103.011 4.79469 103.011 5.95828C103.011 7.12187 102.806 8.13122 102.395 8.95656C101.984 9.7819 101.403 10.4151 100.652 10.8481C99.9004 11.2837 99.0003 11.5002 97.9571 11.5002C96.9138 11.5002 96.0435 11.2864 95.2894 10.8616C94.538 10.4368 93.9569 9.81707 93.5461 9.00527C93.1353 8.19346 92.9299 7.20035 92.9299 6.02864C92.9299 4.85693 93.138 3.8557 93.5515 3.03036L93.5542 3.03306ZM97.9706 2.49727C97.0922 2.49727 96.4327 2.80846 95.9921 3.42814C95.5516 4.04782 95.3327 4.90834 95.3327 6.00158C95.3327 7.09481 95.5516 7.95533 95.9921 8.57501C96.4327 9.19469 97.0895 9.50588 97.9706 9.50588C98.8517 9.50588 99.4976 9.19469 99.9409 8.57501C100.384 7.95533 100.608 7.09752 100.608 6.00158C100.608 4.90563 100.387 4.04782 99.9409 3.42814C99.4949 2.80846 98.8409 2.49727 97.9706 2.49727Z"
        fill="black"
      />
      <path
        d="M106.057 0.765137H109.925C110.922 0.765137 111.711 0.90585 112.298 1.18186C112.884 1.46059 113.303 1.83672 113.557 2.31028C113.812 2.78383 113.939 3.31963 113.939 3.91495C113.939 4.58063 113.803 5.15702 113.536 5.64681C113.268 6.1366 112.849 6.52356 112.284 6.80499L114.452 11.2348H112.152L110.306 7.28937C110.13 7.30019 109.96 7.3029 109.793 7.3029H108.195V11.2348H106.057V0.765137ZM109.895 5.56022C110.628 5.56022 111.125 5.42492 111.39 5.15702C111.655 4.88912 111.787 4.50487 111.787 4.00696C111.787 3.50905 111.647 3.14644 111.368 2.89207C111.09 2.63771 110.565 2.51052 109.793 2.51052H108.195V5.56022H109.895Z"
        fill="black"
      />
      <path
        d="M129.428 0.765411H131.772L133.485 8.20157L135.345 0.824944H137.41L139.242 8.18804L140.956 0.765411H143.21L140.426 11.2377H138.156L136.31 3.9477L134.507 11.2377H132.253L129.428 0.765411Z"
        fill="black"
      />
      <path
        d="M145.988 3.03306C146.404 2.20773 146.991 1.57993 147.753 1.14696C148.515 0.716706 149.407 0.50293 150.434 0.50293C151.461 0.50293 152.348 0.714 153.102 1.14155C153.853 1.5664 154.432 2.18608 154.837 2.99518C155.243 3.80699 155.445 4.79469 155.445 5.95828C155.445 7.12187 155.24 8.13122 154.829 8.95656C154.418 9.7819 153.837 10.4151 153.086 10.8481C152.334 11.2837 151.434 11.5002 150.391 11.5002C149.348 11.5002 148.477 11.2864 147.723 10.8616C146.972 10.4368 146.391 9.81707 145.98 9.00527C145.569 8.19346 145.364 7.20035 145.364 6.02864C145.364 4.85693 145.572 3.8557 145.985 3.03036L145.988 3.03306ZM150.405 2.49727C149.526 2.49727 148.867 2.80846 148.426 3.42814C147.986 4.04782 147.767 4.90834 147.767 6.00158C147.767 7.09481 147.986 7.95533 148.426 8.57501C148.867 9.19469 149.523 9.50588 150.405 9.50588C151.286 9.50588 151.932 9.19469 152.375 8.57501C152.818 7.95533 153.042 7.09752 153.042 6.00158C153.042 4.90563 152.821 4.04782 152.375 3.42814C151.929 2.80846 151.275 2.49727 150.405 2.49727Z"
        fill="black"
      />
      <path
        d="M158.475 0.765625H161.186L163.851 8.49404L166.562 0.765625H169.227V11.2379H167.102V4.24287L164.656 11.2379H162.956L160.553 4.2997V11.2379H158.472V0.765625H158.475Z"
        fill="black"
      />
      <path
        d="M175.643 0.765625H177.781L181.705 11.2379H179.376L178.54 8.81876H174.792L173.957 11.2379H171.716L175.643 0.765625ZM177.973 7.01384L176.654 3.09822L175.351 7.01384H177.973Z"
        fill="black"
      />
      <path
        d="M184.2 0.765625H186.265L190.63 7.64434V0.765625H192.798V11.2379H190.762L186.368 4.37546V11.2379H184.2V0.765625Z"
        fill="black"
      />
      <path
        d="M206.693 0.765625H208.993V9.22737H213.915L213.666 11.2352H206.693V0.765625Z"
        fill="black"
      />
      <path d="M216.55 0.765625H218.85V11.2379H216.55V0.765625Z" fill="black" />
      <path
        d="M222.22 0.765625H229.15V2.65714H224.493V5.25222H228.875V7.14373H224.493V11.2352H222.223V0.765625H222.22Z"
        fill="black"
      />
      <path
        d="M232.064 0.765625H239.315V2.62737H234.334V4.95997H239.021V6.82171H234.334V9.3735H239.607L239.372 11.2352H232.061V0.765625H232.064Z"
        fill="black"
      />
      <path
        d="M253.656 0.765625H260.586V2.65714H255.927V5.25222H260.308V7.14373H255.927V11.2352H253.656V0.765625Z"
        fill="black"
      />
      <path
        d="M263.513 0.765625H267.381C268.378 0.765625 269.17 0.906338 269.754 1.18235C270.341 1.46107 270.76 1.83721 271.014 2.31077C271.268 2.78432 271.395 3.32011 271.395 3.91544C271.395 4.58112 271.26 5.15751 270.992 5.6473C270.724 6.13709 270.305 6.52405 269.741 6.80548L271.908 11.2352H269.608L267.762 7.28986C267.586 7.30068 267.416 7.30339 267.249 7.30339H265.651V11.2352H263.513V0.765625ZM267.351 5.56071C268.084 5.56071 268.581 5.4254 268.846 5.15751C269.111 4.88961 269.243 4.50535 269.243 4.00745C269.243 3.50954 269.103 3.14693 268.824 2.89256C268.546 2.6382 268.022 2.51101 267.249 2.51101H265.651V5.56071H267.351Z"
        fill="black"
      />
      <path
        d="M274.516 0.765625H281.768V2.62737H276.787V4.95997H281.473V6.82171H276.787V9.3735H282.06L281.825 11.2352H274.516V0.765625Z"
        fill="black"
      />
      <path
        d="M284.828 0.765625H292.079V2.62737H287.098V4.95997H291.784V6.82171H287.098V9.3735H292.371L292.139 11.2352H284.828V0.765625Z"
        fill="black"
      />
      <path
        d="M295.139 0.765625H299.109C300.758 0.765625 302.028 1.19588 302.917 2.0564C303.806 2.91692 304.25 4.20769 304.25 5.92872C304.25 7.64976 303.782 8.98924 302.85 9.88764C301.917 10.7887 300.612 11.2379 298.931 11.2379H295.136V0.765625H295.139ZM299.023 9.2869C300.009 9.2869 300.733 8.99736 301.198 8.42097C301.66 7.84459 301.893 7.02196 301.893 5.95849C301.893 4.89502 301.677 4.11027 301.242 3.55283C300.806 2.99539 300.117 2.71667 299.168 2.71667H297.409V9.2869H299.02H299.023Z"
        fill="black"
      />
      <path
        d="M307.569 3.03306C307.985 2.20773 308.571 1.57993 309.334 1.14696C310.096 0.716706 310.99 0.50293 312.015 0.50293C313.039 0.50293 313.928 0.714 314.68 1.14155C315.434 1.5664 316.01 2.18608 316.418 2.99518C316.823 3.80699 317.026 4.79469 317.026 5.95828C317.026 7.12187 316.82 8.13122 316.412 8.95656C316.001 9.7819 315.42 10.4151 314.669 10.8481C313.918 11.2837 313.02 11.5002 311.974 11.5002C310.928 11.5002 310.061 11.2864 309.307 10.8616C308.555 10.4368 307.974 9.81707 307.563 9.00527C307.153 8.19346 306.95 7.20035 306.95 6.02864C306.95 4.85693 307.155 3.8557 307.571 3.03036L307.569 3.03306ZM311.985 2.49727C311.107 2.49727 310.447 2.80846 310.007 3.42814C309.566 4.04782 309.347 4.90834 309.347 6.00158C309.347 7.09481 309.566 7.95533 310.007 8.57501C310.445 9.19469 311.104 9.50588 311.985 9.50588C312.866 9.50588 313.509 9.19469 313.955 8.57501C314.399 7.95533 314.62 7.09752 314.62 6.00158C314.62 4.90563 314.399 4.04782 313.955 3.42814C313.509 2.80846 312.855 2.49727 311.985 2.49727Z"
        fill="black"
      />
      <path
        d="M320.058 0.765625H322.769L325.434 8.49404L328.145 0.765625H330.81V11.2379H328.686V4.24287L326.24 11.2379H324.54L322.137 4.2997V11.2379H320.056V0.765625H320.058Z"
        fill="black"
      />
    </svg>
  </button>
);
