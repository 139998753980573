import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";
import { RemoveScroll } from "react-remove-scroll";
import { GatsbyImage } from "gatsby-plugin-image";

// Icons
import { SplashLogoFull } from "../icons/splash-logo-full";
import { SplashLogoShort } from "../icons/splash-logo-short";

const SplashContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #121929;
  cursor: pointer;

  z-index: 1000;

  & .artists-name-container,
  & .artists-thumbnail-container {
    transition: 300ms opacity ease;
  }

  &.artworks-visible {
    & .artists-name-container,
    & .artists-thumbnail-container {
      opacity: 1;
      visibility: visible;
    }
  }

  &.artworks-hidden {
    & .artists-name-container,
    & .artists-thumbnail-container {
      opacity: 0;
      visibility: hidden;
    }
  }

  & .artists-name-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    height: 100%;
    padding: 100px 20px;
  }

  & h1 {
    font-size: 45px;
    line-height: 125%;

    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;

    color: #fff;

    margin: 20px 0;

    cursor: pointer;

    @media (max-width: 768px) {
      font-size: 26px;
    }
  }

  & .artists-thumbnail-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    width: 33vw;
    height: 100%;
    max-height: 66vh;

    & .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }

    & img {
      object-fit: contain !important;
    }
  }
`;

const SplashLogosContainer = styled.div`
  padding: 20px;

  width: 100%;
  height: 100%;

  &.logo-top {
    & .splash-logo-full-container {
      & svg.short {
        top: 16px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);

        width: 331px;

        @media (max-width: 768px) {
          /* width: 270px; */
          width: calc(${(props) => props.right}px - 19.5px);
          top: 10px;
        }
      }
    }
  }

  & .splash-logo-full-container {
    display: flex;

    position: relative;
    align-items: center;

    width: 100%;
    height: 100%;

    & svg {
      display: block;

      height: auto;

      &.full {
        width: 100%;

        transition: 300ms opacity ease;
        opacity: ${(props) => props.fullLogoOpacity};
      }

      &.short {
        position: fixed;
        width: calc(${(props) => props.right}px - 20px);

        transition: 300ms opacity ease, 900ms top ease, 900ms left ease,
          900ms transform ease;
        opacity: ${(props) => props.groupFourOpacity};

        top: 50%;
        left: 20px;
        transform: translate3d(0, -50%, 0);

        @media (max-width: 767px) {
          width: calc(${(props) => props.right}px - 19.5px);
        }
      }
    }

    & g {
      transition: 300ms opacity ease;

      &#group-1,
      &#group-3 {
        opacity: ${(props) => props.groupOneOpacity};
      }

      &#group-4 {
        opacity: ${(props) => props.groupFourOpacity};
      }
    }
  }
`;

export const Splash = ({ isSplashOpen, setIsSplashOpen }) => {
  const [hasSplashRanOnce, setHasSplashRanOnce] = useState(false);

  const [activeArtistIndex, setActiveArtistIndex] = useState(null);
  const [groupOneOpacity, setGroupOneOpacity] = useState(1);
  const [groupFourOpacity, setGroupFourOpacity] = useState(0);
  const [fullLogoOpacity, setFullLogoOpacity] = useState(1);

  const [right, setRight] = useState(0);

  const [logoTop, setLogoTop] = useState(false);
  const [artworksVisible, setArtworksVisible] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      prismicHome {
        data {
          artist {
            artist_name {
              richText
            }
            artwork {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToSection = (section) => {
    const position = document
      .getElementById(section)
      .getBoundingClientRect().top;

    window.scrollTo({
      top: position - 52,
      left: 0,
      behavior: "smooth",
    });

    setIsSplashOpen(false);
  };

  const getFullLogoDims = () => {
    const logoPosition = document
      .getElementById("group-2")
      .getBoundingClientRect().right;

    return logoPosition;
  };

  const artistNames = data.prismicHome.data.artist.map((artist, index) => (
    <div
      className="single-artist-name"
      key={`single_artist_name_${index}`}
      onMouseEnter={() => setActiveArtistIndex(index)}
      onMouseLeave={() => setActiveArtistIndex(null)}
      onClick={() => scrollToSection(`artist-${index}`)}
    >
      <PrismicRichText field={artist.artist_name.richText} />
    </div>
  ));

  const artistThumbnails = data.prismicHome.data.artist.map((artist, index) => (
    <GatsbyImage
      image={artist.artwork.gatsbyImageData}
      key={`single_artist_thumbnail_${index}`}
    />
  ));

  const triggerSplashAnimation = () => {
    setRight(getFullLogoDims());
    setGroupOneOpacity(0);

    const fadeInGroupFour = setTimeout(() => {
      setGroupFourOpacity(1);

      const fadeOutFullLogo = setTimeout(() => {
        setFullLogoOpacity(0);

        const transitionLogoToTop = setTimeout(() => {
          setLogoTop(true);

          const fadeInArtworks = setTimeout(() => {
            setArtworksVisible(true);
          }, 600);
          return () => clearTimeout(fadeInArtworks);
        }, 300);
        return () => clearTimeout(transitionLogoToTop);
      }, 10);
      return () => clearTimeout(fadeOutFullLogo);
    }, 300);
    return () => clearTimeout(fadeInGroupFour);
  };

  return (
    <RemoveScroll>
      <SplashContainer
        className={artworksVisible ? `artworks-visible` : `artworks-hidden`}
        onClick={() => triggerSplashAnimation()}
      >
        <SplashLogosContainer
          className={`${logoTop ? `logo-top` : `logo-center`}`}
          fullLogoOpacity={fullLogoOpacity}
          groupOneOpacity={groupOneOpacity}
          groupFourOpacity={groupFourOpacity}
          right={Math.round(right)}
          // onClick={() => setIsSplashOpen(false)}
        >
          <div className="splash-logo-full-container">
            <SplashLogoFull />
            <SplashLogoShort />
          </div>
        </SplashLogosContainer>

        <div className="artists-name-container">{artistNames}</div>

        {activeArtistIndex !== null && (
          <div className="artists-thumbnail-container">
            <>{artistThumbnails[activeArtistIndex]}</>
          </div>
        )}
      </SplashContainer>
    </RemoveScroll>
  );
};
